<template>
  <header class="c-topbar">
    <div class="c-topbar__wrapper">
      <nav class="c-topbar__nav">
        <a class="c-topbar__back" href="/" @click.prevent="onBackBtnClick">
          <img class="c-topbar__back-image" src="@/assets/svg/back-arrow.svg" />
        </a>
      </nav>
      <TheHeading level="h1" class="c-topbar__heading">{{ title }}</TheHeading>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import useTest from '@/composables/useTest'
import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading.vue'

export default defineComponent({
  components: {
    TheHeading,
  },

  setup() {
    const { closeModal, setModal } = useModal()
    const { stopTest } = useTest()
    const { t } = useI18n()
    const router = useRouter()
    const title = `${t('TOPBAR_TEST_HEADING')}`
    const onBackBtnClick = () => {
      setModal('ModalAction', {
        title: t('ABORT_MODAL_TITLE'),
        content: t('ABORT_MODAL_CONTENT'),
        type: 'primary',
        actions: {
          confirm: {
            title: t('ABORT_MODAL_CONFIRM'),
            type: 'primary-alt',
            on() {
              closeModal()
            },
          },
          cancel: {
            title: t('ABORT_MODAL_CANCEL'),
            type: 'primary',
            on() {
              router.replace('/')
              stopTest()
              closeModal()
            },
          },
        },
      })
    }

    return {
      title,
      onBackBtnClick,
    }
  },
})
</script>

<style lang="scss">
.c-topbar {
  background: $topbar-bg;
  color: $color-text-2;
  position: fixed;
  height: rem($topbar-height);
  max-height: rem($topbar-height);
  left: 0;
  right: 0;
  top: 0;
}

.c-topbar__wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.c-topbar__nav {
  margin: 0 rem(16px) 0 0;
}

.c-topbar__heading {
  margin: 0 rem(24px) 0 0;
}
</style>
