
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import useTest from '@/composables/useTest'
import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading.vue'

export default defineComponent({
  components: {
    TheHeading,
  },

  setup() {
    const { closeModal, setModal } = useModal()
    const { stopTest } = useTest()
    const { t } = useI18n()
    const router = useRouter()
    const title = `${t('TOPBAR_TEST_HEADING')}`
    const onBackBtnClick = () => {
      setModal('ModalAction', {
        title: t('ABORT_MODAL_TITLE'),
        content: t('ABORT_MODAL_CONTENT'),
        type: 'primary',
        actions: {
          confirm: {
            title: t('ABORT_MODAL_CONFIRM'),
            type: 'primary-alt',
            on() {
              closeModal()
            },
          },
          cancel: {
            title: t('ABORT_MODAL_CANCEL'),
            type: 'primary',
            on() {
              router.replace('/')
              stopTest()
              closeModal()
            },
          },
        },
      })
    }

    return {
      title,
      onBackBtnClick,
    }
  },
})
