<template>
  <div ref="timerEl" class="c-test-timer">
    {{ milisecondsToTime(timeLeft) }}
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import useTest from '@/composables/useTest'
import useModal from '@/composables/useModal'
import tenantConfig from '@/config/tenants/trafikk'
import { milisecondsToTime } from '@/utils/converters'
import { useTippy } from 'vue-tippy'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { closeModal, setModal } = useModal()
    const timeWarningValue = ref<number>(null)
    const { timeLeft, timerRunning, submitTest } = useTest()
    const timerEl = ref(null)
    const { t } = useI18n()

    const { show, hide } = useTippy(timerEl, {
      content: computed(() =>
        t('TIMER_WARNING', [milisecondsToTime(timeWarningValue.value, true)]),
      ),
      trigger: 'manual',
      theme: 'trafikk',
    })

    watch(timeLeft, (value) => {
      if (tenantConfig.testLogic.warningTimes.includes(value)) {
        timeWarningValue.value = value
        show()
        setTimeout(() => {
          hide()
        }, 3000)
      }

      if (timerRunning.value && timeLeft.value <= 0) {
        setModal(
          'ModalAction',
          {
            title: t('TIMER_MODAL_TITLE'),
            content: t('TIMER_MODAL_CONTENT'),
            type: 'primary',
            actions: {
              confirm: {
                title: t('TIMER_MODAL_CONFIRM'),
                on() {
                  submitTest()
                  closeModal()
                },
              },
            },
          },
          {
            canEscape: false,
          },
        )
      }
    })

    return {
      milisecondsToTime,
      timeLeft,
      timerEl,
    }
  },
})
</script>

<style lang="scss">
.c-test-timer {
  font-size: rem(14px);
}
</style>
