import { Question } from 'types/interface/ComposeQuestion'
import tenantConfig from '@/config/tenants/trafikk'

const drawQuestions = (
  source: Question[],
  numToDraw: number,
  preselectedQuestions: Question[] = [],
): Question[] => {
  numToDraw -= preselectedQuestions.length
  source = source.filter((q) => !preselectedQuestions.includes(q))
  const drawn: Question[] = preselectedQuestions
  for (let i = 0; i < numToDraw; i++) {
    const question: Question = source.splice(
      Math.floor(Math.random() * source.length),
      1,
    )[0]
    if (question) {
      drawn.push(question)
    }
  }
  return drawn
}

const shuffleQuestions = (array: Question[]): Question[] => {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const selectQuestions = (
  questionsPool: Question[],
  testType: string,
  preselectedQuestionsIds: string[] = [],
): {
  selectedQuestions: Question[]
  remainingPreselectedIds: string[]
} => {
  const allQuestions: Question[] = [...questionsPool]
  const selectedQuestions: Question[] = []
  const pulledFromPreselected: Question[] = []

  const primaryPool = preselectedQuestionsIds
    .map((qid) => {
      return questionsPool.find((question) => question.id === qid)
    })
    .filter((q) => q !== undefined)

  for (const [questionsKey, categories] of Object.entries(
    tenantConfig.testLogic.questionSpread,
  )) {
    for (const [categoryKey, spread] of Object.entries(categories)) {
      for (const [weight, numToDraw] of Object.entries(spread)) {
        const subsetName = tenantConfig.questionSets[testType][questionsKey]
        const subsetCategories = tenantConfig.questions[subsetName]
        const categoryQuestions = allQuestions.filter(
          (q) =>
            q.category === subsetCategories[categoryKey] &&
            q.weight === +weight,
        )

        const categoryPreselected = primaryPool.filter(
          (q) =>
            q.category === subsetCategories[categoryKey] &&
            q.weight === +weight,
        )

        const preselectedQuestions: Question[] = drawQuestions(
          categoryPreselected,
          numToDraw,
        )

        const drawnQuestions: Question[] = drawQuestions(
          categoryQuestions,
          numToDraw,
          preselectedQuestions,
        )
        selectedQuestions.push(...drawnQuestions)
        pulledFromPreselected.push(...preselectedQuestions)
      }
    }
  }

  const remainingPreselectedIds = primaryPool
    .filter((q) => !pulledFromPreselected.includes(q))
    .map((q) => q.id)

  return {
    selectedQuestions: shuffleQuestions(selectedQuestions),
    remainingPreselectedIds,
  }
}

export { selectQuestions }
