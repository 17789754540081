<template>
  <div v-if="currentQuestion" class="c-test-content">
    <TestMedia v-if="questionVideo" :media="questionVideo" />
    <TestMedia v-if="questionImage" :media="questionImage" />
    <div
      class="c-test-content__question"
      v-md-html="currentQuestion.text"
    ></div>
    <div class="c-test-content__answers">
      <TestAnswer
        v-for="answer in currentQuestion.answers"
        :key="answer.text"
        :answer="answer"
        :selected="isSelected(answer)"
        @click="saveAnswer(answer)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Answer, QuestionAssets } from 'types/interface/ComposeQuestion'

import useTest from '@/composables/useTest'
import TestAnswer from '@/components/TestAnswer.vue'
import TestMedia from '@/components/TestMedia.vue'

export default defineComponent({
  components: {
    TestAnswer,
    TestMedia,
  },

  setup() {
    const { currentQuestion, currentUserAnswer, saveAnswer } = useTest()

    const isSelected = (answer: Answer) =>
      currentUserAnswer.value.answer === answer

    const questionImage = computed(() =>
      currentQuestion.value.assets.find(
        (asset: QuestionAssets) => asset.type === 'image',
      ),
    )

    const questionVideo = computed(() =>
      currentQuestion.value.assets.find(
        (asset: QuestionAssets) => asset.type === 'video',
      ),
    )

    return {
      currentQuestion,
      questionImage,
      questionVideo,
      saveAnswer,
      isSelected,
    }
  },
})
</script>

<style lang="scss">
.c-test-content__question {
  padding: rem(8px) rem(16px) 0;
}

.c-test-content__answers {
  padding: rem(8px);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
