<template>
  <TopbarTest />
  <div class="v-test">
    <div v-if="loading">Loading...</div>
    <template v-else>
      <TestContent />
      <TestNavigation />
    </template>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, onBeforeMount, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import useTest from '@/composables/useTest'
import useQuestions from '@/composables/useQuestions'
import TopbarTest from '@/components/TopbarTest.vue'
import TestContent from '@/components/TestContent.vue'
import TestNavigation from '@/components/TestNavigation.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    TopbarTest,
    TestContent,
    TestNavigation,
  },

  setup() {
    const { tenant } = useTenant()
    const { t } = useI18n()

    const { fetchAllQuestions } = useQuestions()
    const { startTest } = useTest()
    const loading = ref<boolean>(true)
    const route = useRoute()
    const testSet: string = route.params.set.toString()

    useHead({
      title: computed(
        () => `${t('TOPBAR_TEST_HEADING')} - ${tenant.value.name}`,
      ),
    })

    onBeforeMount(async () => {
      await fetchAllQuestions(testSet)
      await startTest(testSet)

      loading.value = false
    })

    return {
      loading,
    }
  },
})
</script>

<style lang="scss">
.v-test {
  padding-top: rem($topbar-height);
  padding-bottom: rem($footer-height);
  height: calc(100vh - rem($topbar-height) - rem($footer-height));
  width: 100%;
  max-width: inherit;
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;
}
</style>
