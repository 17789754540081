import {
  Answer,
  ComposeQuestion,
  Question,
  UserAnswer,
} from 'types/interface/ComposeQuestion'
import { ref, computed } from 'vue'
import { selectQuestions } from '../utils/testLogic'
import tenantConfig from '@/config/tenants/trafikk'
import router from '@/router'
import {
  saveResult,
  setPreselectedQuestions,
  getPreselectedQuestions,
} from '@/services/results'
import useQuestions from '@/composables/useQuestions'

const { allQuestions } = useQuestions()
const questionsPool = ref<Question[]>([])
const questionsSet = ref<Question[]>([])
const userAnswers = ref<UserAnswer[]>([])
const startedTime = ref<number>(null)
const currentTime = ref<number>(null)
const timerRunning = ref<boolean>(false)
const remainingPreselectedQuestions = ref<string[]>(null)
const remainingCorrectIds = ref<string[]>(null)
const timeLeft = computed<number>(() =>
  Math.max(
    0,
    Math.ceil(
      (tenantConfig.testLogic.testTime -
        (currentTime.value - startedTime.value)) /
        1000,
    ) * 1000,
  ),
)
const currentQuestion = computed<Question>(
  () => questionsSet.value && questionsSet.value[currentQuestionIndex.value],
)
const currentUserAnswer = computed<UserAnswer>(() => getCurrenUserAnswer())
const answersCount = computed<number>(
  () => userAnswers.value.filter((a) => a.answer).length,
)
const currentQuestionIndex = ref<number>(0)

let testTimer: number

const startTest = async (requestedTest: string): Promise<void> => {
  clearQuestions()
  const preselectedQuestionsInfo = await getPreselectedQuestions()
  const correctIds = preselectedQuestionsInfo?.correctIds
    ? preselectedQuestionsInfo.correctIds
    : []

  setQuestionsPool(allQuestions.value)
  const drwaingResults: {
    selectedQuestions: Question[]
    remainingPreselectedIds: string[]
  } = selectQuestions(
    questionsPool.value,
    requestedTest,
    preselectedQuestionsInfo ? preselectedQuestionsInfo.questionIds : [],
  )

  remainingPreselectedQuestions.value = drwaingResults.remainingPreselectedIds
  remainingCorrectIds.value = correctIds.filter((cId) =>
    remainingPreselectedQuestions.value.includes(cId),
  )

  questionsSet.value = drwaingResults.selectedQuestions

  questionsSet.value.forEach((question) => {
    userAnswers.value.push({
      questionId: question.id,
      answer: null,
    })
  })

  startedTime.value = currentTime.value = Date.now()
  currentQuestionIndex.value = 0
  testTimer = startTimer()
}

const stopTest = (): void => {
  timerRunning.value = false
  clearQuestions()
  clearTimer()
  startedTime.value = null
}

const setQuestionsPool = (questions: Question[]): void => {
  questionsPool.value = questions
}
const clearQuestions = (): void => {
  questionsPool.value = []
  questionsSet.value = []
  userAnswers.value = []
}

const saveAnswer = (answer: Answer): void => {
  const userAnswer = getCurrenUserAnswer()
  if (userAnswer) {
    userAnswer.answer = answer
  }
}
const nextQuestion = (): void => {
  if (currentQuestionIndex.value < questionsSet.value.length - 1) {
    currentQuestionIndex.value++
  }
}

const prevQuestion = (): void => {
  if (currentQuestionIndex.value > 0) {
    currentQuestionIndex.value--
  }
}

function getCurrenUserAnswer(): UserAnswer {
  return userAnswers.value[currentQuestionIndex.value]
}

const startTimer = (): number => {
  timerRunning.value = true
  return setInterval(() => {
    currentTime.value = Date.now()
    if (timeLeft.value <= 0) {
      clearTimer()
    }
  }, 1000)
}
const clearTimer = () => {
  if (testTimer) {
    clearInterval(testTimer)
  }
}
const submitTest = async () => {
  const resultsTimestamp = await saveResult(userAnswers.value)

  let preselectedIds = remainingPreselectedQuestions.value

  const wrongAnsweredIds: string[] = userAnswers.value
    .filter((userAnswer) => !userAnswer.answer?.correct)
    .map((userAnswer) => userAnswer.questionId)

  preselectedIds = [...preselectedIds, ...wrongAnsweredIds]

  preselectedIds = [...new Set(preselectedIds)]

  await setPreselectedQuestions(preselectedIds, remainingCorrectIds.value)
  router.replace({ name: 'Results', params: { resultsTimestamp } })
}

export default (): ComposeQuestion => ({
  currentQuestion,
  currentQuestionIndex,
  questionsSet,
  currentUserAnswer,
  timeLeft,
  timerRunning,
  answersCount,
  submitTest,
  startTest,
  stopTest,
  saveAnswer,
  nextQuestion,
  prevQuestion,
})
