<template>
  <nav class="c-test-navigation">
    <TheButton
      type="secondary"
      icon="arrow-left"
      :hidden="currentQuestionIndex === 0"
      class="c-test-navigation__button c-test-navigation__button-left"
      @click="prevQuestion()"
      >{{ $t('TEST_NAVIGATION_PREV') }}</TheButton
    >
    <div class="c-test-navigation-info">
      <TestTimer />
      <div class="c-test-progress">
        {{ currentQuestionIndex + 1 }} / {{ questionsSet.length }}
      </div>
    </div>
    <TheButton
      v-if="currentQuestionIndex !== questionsSet.length - 1"
      type="secondary"
      icon="arrow-right"
      iconPos="right"
      class="c-test-navigation__button c-test-navigation__button-right"
      @click="nextQuestion()"
      >{{ $t('TEST_NAVIGATION_NEXT') }}</TheButton
    >
    <TheButton
      v-else
      type="primary"
      class="c-test-navigation__button c-test-navigation__button-right"
      @click="submitClickHandler()"
      >{{ $t('TEST_NAVIGATION_SUBMIT') }}</TheButton
    >
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useTest from '@/composables/useTest'
import useModal from '@/composables/useModal'
import { useI18n } from 'vue-i18n'
import TheButton from '@/components/TheButton.vue'
import TestTimer from '@/components/TestTimer.vue'

export default defineComponent({
  components: {
    TheButton,
    TestTimer,
  },
  setup() {
    const {
      answersCount,
      questionsSet,
      currentQuestionIndex,
      submitTest,
      nextQuestion,
      prevQuestion,
    } = useTest()
    const { closeModal, setModal } = useModal()
    const { t } = useI18n()

    const submitClickHandler = () => {
      const questionsLeft = questionsSet.value.length - answersCount.value

      setModal('ModalAction', {
        title: t('SUBMIT_MODAL_TITLE'),
        content: questionsLeft
          ? t('SUBMIT_MODAL_CONTENT_ALT', [questionsLeft])
          : t('SUBMIT_MODAL_CONTENT'),
        type: 'primary',
        actions: {
          confirm: {
            title: t('SUBMIT_MODAL_CONFIRM'),
            on() {
              submitTest()
              closeModal()
            },
          },
          cancel: {
            title: t('SUBMIT_MODAL_CANCEL'),
            on() {
              closeModal()
            },
          },
        },
      })
    }

    return {
      currentQuestionIndex,
      questionsSet,
      submitClickHandler,
      submitTest,
      nextQuestion,
      prevQuestion,
    }
  },
})
</script>
<style lang="scss">
.c-test-navigation {
  position: fixed;
  background: $test-navigation-bg;
  height: rem($footer-height);
  max-height: rem($footer-height);
  width: 100%;
  max-width: inherit;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 rem(24px) $test-fixed-container-outline;

  &__button {
    min-width: rem(106px);
    max-height: rem(44px);
    &-left {
      margin-left: rem(24px);
    }
    &-right {
      margin-right: rem(24px);
    }
  }

  &-info {
    padding: rem(8px);
    text-align: center;

    .c-test-progress {
      font-size: rem(14px);
      font-weight: 700;
    }
  }
}
</style>
