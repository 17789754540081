
import { defineComponent } from 'vue'
import useTest from '@/composables/useTest'
import useModal from '@/composables/useModal'
import { useI18n } from 'vue-i18n'
import TheButton from '@/components/TheButton.vue'
import TestTimer from '@/components/TestTimer.vue'

export default defineComponent({
  components: {
    TheButton,
    TestTimer,
  },
  setup() {
    const {
      answersCount,
      questionsSet,
      currentQuestionIndex,
      submitTest,
      nextQuestion,
      prevQuestion,
    } = useTest()
    const { closeModal, setModal } = useModal()
    const { t } = useI18n()

    const submitClickHandler = () => {
      const questionsLeft = questionsSet.value.length - answersCount.value

      setModal('ModalAction', {
        title: t('SUBMIT_MODAL_TITLE'),
        content: questionsLeft
          ? t('SUBMIT_MODAL_CONTENT_ALT', [questionsLeft])
          : t('SUBMIT_MODAL_CONTENT'),
        type: 'primary',
        actions: {
          confirm: {
            title: t('SUBMIT_MODAL_CONFIRM'),
            on() {
              submitTest()
              closeModal()
            },
          },
          cancel: {
            title: t('SUBMIT_MODAL_CANCEL'),
            on() {
              closeModal()
            },
          },
        },
      })
    }

    return {
      currentQuestionIndex,
      questionsSet,
      submitClickHandler,
      submitTest,
      nextQuestion,
      prevQuestion,
    }
  },
})
