
import { computed, defineComponent } from 'vue'
import { Answer, QuestionAssets } from 'types/interface/ComposeQuestion'

import useTest from '@/composables/useTest'
import TestAnswer from '@/components/TestAnswer.vue'
import TestMedia from '@/components/TestMedia.vue'

export default defineComponent({
  components: {
    TestAnswer,
    TestMedia,
  },

  setup() {
    const { currentQuestion, currentUserAnswer, saveAnswer } = useTest()

    const isSelected = (answer: Answer) =>
      currentUserAnswer.value.answer === answer

    const questionImage = computed(() =>
      currentQuestion.value.assets.find(
        (asset: QuestionAssets) => asset.type === 'image',
      ),
    )

    const questionVideo = computed(() =>
      currentQuestion.value.assets.find(
        (asset: QuestionAssets) => asset.type === 'video',
      ),
    )

    return {
      currentQuestion,
      questionImage,
      questionVideo,
      saveAnswer,
      isSelected,
    }
  },
})
