import { Question, ComposeAllQuestions } from 'types/interface/ComposeQuestion'
import { ref } from 'vue'
import { useStructures, useContents, useTenant } from '@/core'
import { TenantQuestion } from 'types/interface/Tenant'

const { tenant } = useTenant()
const { fetchObject, getObjectById } = useContents()
const {
  fetchStructuresNode,
  fetchStructuresChildren,
  getStructuresBySlugPath,
} = useStructures()
const allQuestions = ref<Question[]>([])

const questionsByIds = (ids: string[]): Question[] => {
  return ids.map((id) =>
    allQuestions.value.find((question) => question.id === id),
  )
}

const fetchAllQuestions = async (requestedTest: string) => {
  if (!allQuestions.value.length) {
    let questions: Array<Question> = []
    let structures: { childrens: [{ contentId: string }] }

    const questionsGroup: string[] = Object.values(
      tenant.value.questionSets[requestedTest],
    )

    const questionsSet: TenantQuestion[] = questionsGroup.map(
      (set: string) => ({
        type: set,
        slugPaths: tenant.value.questions[set],
      }),
    )

    await Promise.all(
      questionsSet.map(async (q: TenantQuestion) => {
        await Promise.all(
          Object.values(q.slugPaths).map(async (category: string) => {
            await fetchStructuresNode(category)
            await fetchStructuresChildren(category)
            structures = getStructuresBySlugPath(category, true, 'OBJECT')

            await Promise.all(
              structures.childrens.map(async (ch: { contentId: string }) => {
                if (!ch.contentId) return

                await fetchObject(ch.contentId)
                const categorizedQuestions: Question[] = getObjectById(
                  ch.contentId,
                ).content.questions
                categorizedQuestions.forEach((question) => {
                  question.category = category
                  if (question.timestamp) {
                    question.id = question.timestamp
                  }
                })
                questions = [...questions, ...categorizedQuestions]
              }),
            )
          }),
        )
      }),
    )
    allQuestions.value = questions
  }
}

export default (): ComposeAllQuestions => ({
  allQuestions,
  fetchAllQuestions,
  questionsByIds,
})
